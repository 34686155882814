import React from "react";

import "../styles/basic.css";
import imgsHome from "../imagenes/home/imgsHome.js";
// import foto1 from "../imagenes/home/fran.png";

// import cuadros from "../imagenes/fashion/cuadros.jpg";
// import tanque from "../imagenes/fashion/tanque.jpg";
import abajo from "../imagenes/fashion/abajo.jpg";
import blurr from "../imagenes/fashion/blurr.jpg";
import clown from "../imagenes/fashion/clown.jpg";
import naranja from "../imagenes/fashion/naranja.jpg";
import aqua from "../imagenes/fashion/aqua.jpg";
import cahquet from "../imagenes/fashion/cahquet.jpg";
import crom from "../imagenes/fashion/crom.jpg";
import flores from "../imagenes/fashion/flores.jpg";
import tercio from "../imagenes/fashion/tercio.jpg";
import asado from "../imagenes/fashion/asado.jpg";
import dedo from "../imagenes/fashion/dedo.jpg";
import cuero from "../imagenes/fashion/cuero.jpg";
import asc from "../imagenes/fashion/asc.jpg";
import chair from "../imagenes/fashion/chair.jpg";
import dos from "../imagenes/fashion/dos.jpg";
import gorro from "../imagenes/fashion/gorro.jpg";
import pao from "../imagenes/fashion/pao.jpg";
import rosa from "../imagenes/fashion/rosa.jpg";
import balcony from "../imagenes/fashion/balcony.jpg";
import duo from "../imagenes/fashion/duo.jpg";
import insta from "../imagenes/fashion/insta.jpg";

// const descripciones = [
//     {
//     "titulo": "Vertigo",
//     "tecnica": "Collage analógico",
//     "medidas": "43x33cm",
//     "precio": "$75.000 (con marco) $70.000 (sin marco)",
//     "cantidad": "1 obra original",
//     "vendido": true
//     },
//     {
//     "titulo": "Cabezona",
//     "tecnica": "Collage analógico",
//     "medidas": "30x40cm",
//     "precio": "$70.000",
//     "cantidad": "1 obra original",
//     "vendido": true
//     }
//     ];

const Fashion = () => {
  console.log(imgsHome);
  return (
    <>
      <ul className="fotos">
        {/* <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto3}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto4}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto5}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto2}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto6}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto7}></img>
        </li> */}

        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={naranja} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={abajo} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={blurr} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={clown} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={aqua} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={crom} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={flores} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={asado} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={dedo} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={cuero} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={chair} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={asc} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={cahquet} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={dos} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={gorro} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={tercio} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={rosa} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={pao} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={balcony} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={duo} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={insta} />
        </li>
        {/* {fotos.map((foto) => (
          <li className="foto">
            <img className="foto" alt="" src={foto}></img>
          </li>
        ))} */}
      </ul>
      {/* <div className='container-horizontal'>
            <PhotoCarrousel fotos={imgsHome}/>
        </div> */}
    </>
  );
};

export default Fashion;
