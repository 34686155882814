import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./layouts/Home.jsx";
import Fashion from "./layouts/Fashion.jsx";
import Shop from "./layouts/shop.jsx"
import Gallery from "./layouts/Gallery.js"
import AboutUs from "./layouts/AboutUs";
import Sidebar from "./components/Sidebar.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Sidebar />
        <div className="body-container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/fashion" element={<Fashion/>} />
            <Route path="/shop" element={<Shop/>} />
            <Route path="/gallery" element={Gallery} />


            <Route path="/about" element={AboutUs} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
